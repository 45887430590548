<template>
  <div class="game-container">
    <ListenThenChooseWordsGame
      :trumpetImg="trumpetImg"
      :allImgList="allImgList"
      :answerList="answerList"
      :chooseHanziList="chooseHanziList"
    />
  </div>
</template>

<script>
import ListenThenChooseWordsGame from "@/components/Course/GamePage/ListenThenChooseWordsGame";
export default {
  data() {
    return {
      answerList: [1, 6, 11, 13, 20],
      trumpetImg: require("@/assets/img/16-GAMES/G50-stories/trumpter.svg"),
      chooseHanziList: [
        {
          bgImg: require(`@/assets/img/16-GAMES/G31-make-sentences/lesson-16/image-1.svg`),
          wordsPinyin: "Wǒ ài māma!",
          wordsHanzi: "我爱妈妈 ！",
          hanziRightList: [
            {
              pinyin: "wǒ",
              hanzi: "我",
              id: 0,
            },
            {
              pinyin: "ài",
              hanzi: "爱",
              id: 1,
            },
            {
              pinyin: "māma",
              hanzi: "妈妈",
              id: 2,
            },
          ],
          hanziChooseList: [
            // {
            //   pinyin: "bàba",
            //   hanzi: "爸爸",
            //   id: 4,
            // },
            {
              pinyin: "māma",
              hanzi: "妈妈",
              id: 2,
            },
            // {
            //   pinyin: "jiā",
            //   hanzi: "家",
            //   id: 3,
            // },
            {
              pinyin: "ài",
              hanzi: "爱",
              id: 1,
            },
            {
              pinyin: "wǒ",
              hanzi: "我",
              id: 0,
            },
          ],
          audioSrc : require("@/assets/audio/L1/6-Sentences/wo-ai-mama.mp3")
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G31-make-sentences/lesson-16/image-6.svg`),
          wordsPinyin: "Wǒ ài bàba!",
          wordsHanzi: "我爱爸爸！",
          audioSrc : require("@/assets/audio/L1/6-Sentences/wo-ai-baba.mp3"),
          hanziRightList: [
            {
              pinyin: "wǒ",
              hanzi: "我",
              id: 0,
            },
            {
              pinyin: "ài",
              hanzi: "爱",
              id: 1,
            },
            {
              pinyin: "bàba",
              hanzi: "爸爸",
              id: 2,
            },
          ],
          hanziChooseList: [
            {
              pinyin: "bàba",
              hanzi: "爸爸",
              id: 2,
            },
            // {
            //   pinyin: "māma",
            //   hanzi: "妈妈",
            //   id: 3,
            // },
            // {
            //   pinyin: "jiā",
            //   hanzi: "家",
            //   id: 4,
            // },
            {
              pinyin: "ài",
              hanzi: "爱",
              id: 1,
            },
            {
              pinyin: "wǒ",
              hanzi: "我",
              id: 0,
            },
          ],
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G31-make-sentences/lesson-16/image-11.svg`),
          wordsPinyin: "Wǒ ài wǒ jiā!",
          wordsHanzi: "我爱我家！",
          audioSrc : require("@/assets/audio/L1/6-Sentences/wo-ai-wo-jia.mp3"),
          hanziRightList: [
            {
              pinyin: "wǒ",
              hanzi: "我",
              id: 0,
            },
            {
              pinyin: "ài",
              hanzi: "爱",
              id: 1,
            },
            {
              pinyin: "wǒ",
              hanzi: "我",
              id: 0,
            },
            {
              pinyin: "jiā",
              hanzi: "家",
              id: 2,
            },
          ],
          hanziChooseList: [
            {
               pinyin: "wǒ",
              hanzi: "我",
              id: 0,
            },
            // {
            //   pinyin: "māma",
            //   hanzi: "妈妈",
            //   id: 3,
            // },
            {
              pinyin: "jiā",
              hanzi: "家",
              id: 2,
            },
            {
              pinyin: "ài",
              hanzi: "爱",
              id: 1,
            },
            {
              pinyin: "wǒ",
              hanzi: "我",
              id: 0,
            },
          ],
        },
         {
          bgImg: require(`@/assets/img/16-GAMES/G31-make-sentences/lesson-16/image-4.svg`),
          wordsPinyin: "Wǒ yǒu yí ge gēge.",
           wordsHanzi: "我有一个哥哥。",
           audioSrc : require("@/assets/audio/L1/6-Sentences/wo-you-yi-ge-gege.mp3"),
          hanziRightList: [
            {
              pinyin: "wǒ",
              hanzi: "我",
              id: 0,
            },
            {
              pinyin: "yǒu",
              hanzi: "有",
              id: 1,
            },
            {
              pinyin: "yī",
              hanzi: "一",
              id: 2,
            },
             {
              pinyin: "gè",
              hanzi: "个",
              id: 3,
            },
            {
              pinyin: "gēge",
              hanzi: "哥哥",
              id: 4,
            },
          ],
          hanziChooseList: [
            {
              pinyin: "yī",
              hanzi: "一",
              id: 2,
            },
             {
              pinyin: "gè",
              hanzi: "个",
              id: 3,
            },
            {
              pinyin: "gēge",
              hanzi: "哥哥",
              id: 4,
            },
                        {
              pinyin: "yǒu",
              hanzi: "有",
              id: 1,
            },
                        {
              pinyin: "wǒ",
              hanzi: "我",
              id: 0,
            },
          ],
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G31-make-sentences/lesson-16/image-9.svg`),
          wordsPinyin: "wǒ jiā yǒu sān kǒu rén.",
          wordsHanzi: "我家有三口人。",
          hanziRightList: [
            {
              pinyin: "wǒ",
              hanzi: "我",
              id: 0,
            },
            {
              pinyin: "jiā",
              hanzi: "家",
              id: 1,
            },
            {
              pinyin: "yǒu",
              hanzi: "有",
              id: 2,
            },
             {
              pinyin: "sān",
              hanzi: "三",
              id: 3,
            },
            {
              pinyin: "kǒu",
              hanzi: "口",
              id: 4,
            },
                        {
              pinyin: "rén",
              hanzi: "人",
              id: 5,
            },
          ],
          hanziChooseList: [
                                    {
              pinyin: "rén",
              hanzi: "人",
              id: 5,
            },
             {
              pinyin: "sān",
              hanzi: "三",
              id: 3,
            },
            {
              pinyin: "kǒu",
              hanzi: "口",
              id: 4,
            },
                        {
              pinyin: "yǒu",
              hanzi: "有",
              id: 2,
            },
                        {
              pinyin: "jiā",
              hanzi: "家",
              id: 1,
            },
                        {
              pinyin: "wǒ",
              hanzi: "我",
              id: 0,
            },

          ],
        },
      ],
    };
  },
  computed: {
    allImgList() {
      let imgArr = [];
      for (let i = 1; i <= 20; i++) {
        imgArr.push({
          bgImg: require(`@/assets/img/16-GAMES/G31-make-sentences/lesson-16/image-${i}.svg`),
          id: i,
        });
      }
      return [
        imgArr.slice(0, 4),
        imgArr.slice(4, 8),
        imgArr.slice(8, 12),
        imgArr.slice(12, 16),
        imgArr.slice(16, 20),
      ];
    },
  },
  components: {
    ListenThenChooseWordsGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>