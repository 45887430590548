<!--
 * @Author: your name
 * @Date: 2022-03-16 10:06:35
 * @LastEditTime: 2022-03-30 09:12:35
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \nihaokidsNew-front\src\views\Course\Level1\Chapter2\Lesson16\10_SpeakingGame.vue
-->
<template>
  <div class="game-container">
    <SpeakingBubbleGame
      :buttonList="buttonList"
      :gameList="gameList"
      :questionBubbleImg="questionBubbleImg"
      :titleInfo="titleInfo"
    />
  </div>
</template>

<script>
import SpeakingBubbleGame from "@/components/Course/GamePage/SpeakingBubbleGame";
export default {
  data() {
    return {
      questionBubbleImg: require("@/assets/img/16-GAMES/G01-hi/lesson-16/bubble-question-xl.svg"),
      titleInfo: [
        {
        pinyin: "Wǒ jiā yǒu……kǒu rén,",
        hanzi: "我家有……口人，"
        },
        {
        pinyin: "……hé……",
        hanzi: "……和……"
        }],
      gameList: [
        {
          bgImg: require(`@/assets/img/16-GAMES/G42-choose-ab/lesson-16-image-1.svg`),
          bubbleImg: require(`@/assets/img/16-GAMES/G01-hi/lesson-16/bubble-1.svg`),
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G42-choose-ab/lesson-16-image-2.svg`),
          bubbleImg: require(`@/assets/img/16-GAMES/G01-hi/lesson-16/bubble-2.svg`),
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G42-choose-ab/lesson-16-image-3.svg`),
          bubbleImg: require(`@/assets/img/16-GAMES/G01-hi/lesson-16/bubble-3.svg`),
        },
      ],
      buttonList: [
        {
          image: require("@/assets/img/16-GAMES/G01-hi/lesson-16/menu-1.svg"),
          startNumberRange: 1,
          endNumberRange: 1,
        },
        {
          image: require("@/assets/img/16-GAMES/G01-hi/lesson-16/menu-2.svg"),
          startNumberRange: 2,
          endNumberRange: 2,
        },
        {
          image: require("@/assets/img/16-GAMES/G01-hi/lesson-16/menu-3.svg"),
          startNumberRange: 3,
          endNumberRange: 3,
        },
      ],
    };
  },
  components: {
    SpeakingBubbleGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>