<template>
  <div class="game-container">
    <ReviewTrain
      :step="3"
      :vocabularyReviewList="vocabularyReviewList"
      :knowledgeReviewList="knowledgeReviewList"
      :pinyinWordReviewList="pinyinWordReviewList"
      :strokesReviewList="strokesReviewList"
    />
  </div>
</template>

<script>
import ReviewTrain from "@/components/Course/CoursePage/ReviewTrain";
export default {
  data() {
    return {
      vocabularyReviewList: [
        [
          {
            pinyin: "bàba",
            hanzi: "爸爸",
          },
          {
            pinyin: "māma",
            hanzi: "妈妈",
          },
          {
            pinyin: "jiějie",
            hanzi: "姐姐",
          },
          {
            pinyin: "dìdi",
            hanzi: "弟弟",
          },
          {
            pinyin: "gēge",
            hanzi: "哥哥",
          },
          {
            pinyin: "mèimei",
            hanzi: "妹妹",
          },
        ],
        [
          {
            pinyin: "ge",
            hanzi: "个",
          },
          {
            pinyin: "rén",
            hanzi: "人",
          },
          {
            pinyin: "kǒu",
            hanzi: "口",
          },
          {
            pinyin: "hé",
            hanzi: "和",
          },
          {
            pinyin: "yǒu",
            hanzi: "有",
          },
        ],
        [
          {
            pinyin: "ài",
            hanzi: "爱",
          },
          {
            pinyin: "jiā",
            hanzi: "家",
          },
        ],
      ],
      knowledgeReviewList: [
        [
          {
            pinyin: "Wǒ ài…",
            hanzi: "我爱……",
          },
          {
            pinyin: "Wǒ yǒu…",
            hanzi: "我有……",
          },
          {
            pinyin: "Wǒ jiā yǒu … kǒu rén",
            hanzi: "我家有……口人",
          
          },
          {
            pinyin: "… hé …",
            hanzi: "……和……",
          },
        ],
      ],
      pinyinWordReviewList: [
        [
          {
            vocabulary: "b",
          },
          {
            vocabulary: "p",
          },
        ],
        [
          {
            vocabulary: "m",
          },
          {
            vocabulary: "f",
          },
        ],
      ],
      strokesReviewList: [
        [
          {
            strokeImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-10-1/stroke-dian-white.svg"),
          },
          {
            strokeImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-10-2/stroke-hengpie-white.svg"),
          },
        ],
        [
          {
            strokeImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-12-1/hengzhegou-white.svg"),
          },
          {
            strokeImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-14-1/piedian-white.svg"),
          },
        ],
      ],
    };
  },
  components: {
    ReviewTrain,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>