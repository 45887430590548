<template>
  <div class="game-container">
    <WriteHanziReviewPage
      :LeftImgList="LeftImgList"
      :hanziInfoList="hanziInfoList"
      :gifList="gifList"
    />
  </div>
</template>

<script>
import WriteHanziReviewPage from "@/components/Course/CoursePage/WriteHanziReviewPage";
export default {
  data() {
    return {
      hanziInfoList: [
        {
          pinyin: "rén",
          tipsList: ["我", "家", "有", "五", "口", "人", "。"],
          bgImg: require("@/assets/img/16-GAMES/G20-writing-hanzi/lesson-16/hanzi-ren-grey-border.svg"),
          stepNumber: 2,
          tipsIndex: 5,
        },
        {
          pinyin: "yǒu",
          tipsList: ["我", "有", "三", "个", "弟", "弟", "。"],
          bgImg: require("@/assets/img/16-GAMES/G20-writing-hanzi/lesson-12/hanzi-you-grey-border.svg"),
          stepNumber: 6,
          tipsIndex: 1,
        },
        {
          pinyin: "mā",
          tipsList: ["我", "爱", "妈", "妈", "。"],
          bgImg: require("@/assets/img/16-GAMES/G20-writing-hanzi/lesson-10/hanzi-ma-grey-border.svg"),
          stepNumber: 6,
          tipsIndex: 2,
        },
      ],
    };
  },
  computed: {
    LeftImgList() {
      const imgArr = [];
      for (let i = 1; i <= 3; i++) {
        imgArr.push(
          require(`@/assets/img/16-GAMES/G20-writing-hanzi/lesson-16/image-${i}.svg`)
        );
      }
      return imgArr;
    },
    gifList() {
      let imgArrMa = [];
      for (let i = 1; i <= 6; i++) {
        imgArrMa.push(
          require(`@/assets/img/16-GAMES/G20-writing-hanzi/lesson-10/lesson-10-ma-${i}.gif`)
        );
      }
      let imgArrYou = [];
      for (let i = 1; i <= 6; i++) {
        imgArrYou.push(
          require(`@/assets/img/16-GAMES/G20-writing-hanzi/lesson-12/lesson-12-you-${i}.gif`)
        );
      }
      let imgArrRen = [];
      for (let i = 1; i <= 2; i++) {
        imgArrRen.push(
          require(`@/assets/img/16-GAMES/G20-writing-hanzi/lesson-14/lesson-14-ren-${i}.gif`)
        );
      }
      return [imgArrRen, imgArrYou, imgArrMa];
    },
  },
  components: {
    WriteHanziReviewPage,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>
