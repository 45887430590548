<template>
  <div class="game-container">
    <RecognitionPinyinGame :optionList="optionList" :imgList="imgList" :isHaveAudio="true" :isFullScreen="true"/>
  </div>
</template>

<script>
import RecognitionPinyinGame from "@/components/Course/GamePage/RecognitionPinyinGame";
export default {
  data() {
    return {
      lessonId: 8,
      optionList: [
        {
          id: 1,
          image: require("@/assets/img/16-GAMES/G51-frogs/chapter-02/background-b.svg"),
        },
        {
          id: 4,
          image: require("@/assets/img/16-GAMES/G51-frogs/chapter-02/background-p.svg"),
        },
        {
          id: 3,
          image: require("@/assets/img/16-GAMES/G51-frogs/chapter-02/background-m.svg"),
        },
         {
          id: 2,
          image: require("@/assets/img/16-GAMES/G51-frogs/chapter-02/background-f.svg"),
        },
      ],

      imgList: [
        {
          id: 1,
          bottomImg: require("@/assets/img/16-GAMES/G51-frogs/chapter-02/frog-b.svg"),
          nobgcolor: true,
          audioSrc: require("@/assets/audio/L1/2-Pinyin/b.mp3"),

        },
        {
          id: 2,
          bottomImg: require("@/assets/img/16-GAMES/G51-frogs/chapter-02/frog-f.svg"),
          nobgcolor: true,
          audioSrc: require("@/assets/audio/L1/2-Pinyin/p.mp3"),

        },
        {
          id: 3,
          bottomImg: require("@/assets/img/16-GAMES/G51-frogs/chapter-02/frog-m.svg"),
          nobgcolor: true,
          audioSrc: require("@/assets/audio/L1/2-Pinyin/m.mp3"),

        },
        {
          id: 4,
          bottomImg: require("@/assets/img/16-GAMES/G51-frogs/chapter-02/frog-p.svg"),
          nobgcolor: true,
          audioSrc: require("@/assets/audio/L1/2-Pinyin/f.mp3"),

        },
      ],
    };
  },
  components: {
    RecognitionPinyinGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>











