<template>
  <div class="game-container">
    <FindElementByPicReviewGame
      :asideElementInfo="asideElementInfo"
      :questionInfoList="questionInfoList"
      :totalStars="totalStars"
      :isNumberGame="false"
      :background="`blue`"
    />
  </div>
</template>

<script>
import FindElementByPicReviewGame from "@/components/Course/GamePage/FindElementByPicReviewGame";
export default {
  data() {
    return {
      totalStars: 6,
      questionInfoList: [{
          id: 1,
          bgImg: require("@/assets/img/12-Vocabulary/lesson-09-mama.svg"),
        },
        {
          id: 2,
          bgImg: require("@/assets/img/12-Vocabulary/lesson-13-jiejie.svg"),
        },
         {
          id: 3,
          bgImg: require("@/assets/img/12-Vocabulary/lesson-13-didi.svg"),
        },
         {
          id: 4,
          bgImg: require("@/assets/img/12-Vocabulary/lesson-11-gege.svg"),
        },
        {
          id: 5,
          bgImg: require("@/assets/img/12-Vocabulary/lesson-09-baba.svg"),
        },
        {
          id: 6,
          bgImg: require("@/assets/img/12-Vocabulary/lesson-11-meimei.svg"),
        }
      ],
      asideElementInfo: {
        leftList: [
          {
            id: 5,
            element: "爸爸",
            pinyin: "bàba",
            sound: require("@/assets/audio/L1/5-Words/baba.mp3")

          },
          {
            id: 1,
            element: "妈妈",
            pinyin: "māma",
            sound: require("@/assets/audio/L1/5-Words/mama.mp3")
          },
          {
            id: 4,
            element: "哥哥",
            pinyin: "gēge",
            sound: require("@/assets/audio/L1/5-Words/gege.mp3")
          }
        ],
        rightList: [
          {
            id: 2,
            element: "姐姐",
            pinyin: "jiějie",
            sound: require("@/assets/audio/L1/5-Words/jiejie.mp3")
          },
          {
            id: 3,
            element: "弟弟",
            pinyin: "dìdi",
            sound: require("@/assets/audio/L1/5-Words/didi.mp3")
          },
          {
            id: 6,
            element: "妹妹",
            pinyin: "mèimei",
            sound: require("@/assets/audio/L1/5-Words/meimei.mp3")
          }
        ]
      }
    };
  },
  components: {
    FindElementByPicReviewGame
  }
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>
